(function() {
  /**
   * Calls a function when the time is up
   *
   * @param {function} func The function to debounce
   * @param {number} wait Time to wait in milliseconds
   * @param {boolean} immediate Trigger now
   */
  function debounce(func, wait, immediate) {
    var timeout;

    return function() {
      var self = this;
      var args = arguments;

      var later = function() {
        timeout = null;
        if (!immediate) {
          func.apply(self, args);
        }
      };

      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);

      if (callNow) {
        func.apply(self, args);
      }
    };
  }

  window.debounce = debounce;

})();
