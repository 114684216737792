jQuery(function($) {
  var $slickSlider = false;
  // nodes to hide from mobile screen readers
  // all top level nodes, except header (those are cherry picked)
  // https://github.com/focus-trap/focus-trap/issues/80
  const $nodeToAriaHide = $('body')
    .children(':not(script):not(header)')
    .add($('body')
      .children('header')
      .find('.header-logo, .navbar')
    );

  function trapit() {
    window.video = $('' + window.location.hash).children('video').get(0);
    window.trap = focusTrap.createFocusTrap($('' + window.location.hash)[0], {
      onActivate: function() {
        $nodeToAriaHide
          .attr('aria-hidden', true)
          .css('cursor', 'default');
      },
      onDeactivate: function() {
        $nodeToAriaHide
          .removeAttr('aria-hidden')
          .removeAttr('style');
      }
    });
  }

  // If the modal's open on load, initialize focus trap.
  if (window.location.hash && $('' + window.location.hash).hasClass('lightbox')) {
    trapit();
    window.trap.activate();
  }

  $(window).bind('hashchange', function(e) {
    // If you're in a modal, trap focus. If the modal contains a video, play it.
    if (window.location.hash && $('' + window.location.hash).hasClass('lightbox')) {
      trapit();
      if (window.video) {
        window.video.play();
      }
      window.trap.activate();
    }
    // Clicking to any anchor automatically kills all focus traps.
    else {
      if (window.trap) {
        window.trap.deactivate();
      }
      // Clicking an anchor with '#_' as its hash will stop the video. This is for closing modals (the close button always has '#_' as its URL).
      if (window.location.hash === '#_') {
        $('video').each(function() {
          $(this).get(0).pause();
        });
      }
    }
  });

  $(document).keyup(function(e) {
    if (e.key === 'Escape') {
      if ($('' + window.location.hash).hasClass('lightbox')) {
        window.location.hash = '_';
      }
    }

  });

  $('[type=radio],[type=checkbox]').click(function() {
    $(this).blur();
  });

  $('.playvideo video').one('click', function() {
    var video = $(this);
    video.get(0).play();
    video.siblings('button').hide();
    setTimeout(
      function() {
        video.attr('controls', '');
      }, 500);
  });

  $('.slidecontrol a').click(function() {
    $('.slidecontrol a').removeClass('active');
    $(this).addClass('active');
  });

  function slickSlider() {
    if ($(window).width() < 768) {
      if (!$slickSlider) {
        $('.content-teaser > div').slick({
          dots: true,
          arrows: true,
          slidesToScroll: 1
        });
        $slickSlider = true;
      }
    }
    else if ($(window).width() > 768) {
      if ($slickSlider) {
        $('.content-teaser > div').slick('unslick');
        $slickSlider = false;
      }
    }
  }

  $(document).ready(function() {
    slickSlider();
  });

  $(window).on('resize', function() {
    slickSlider();
  });
});
